<template>
  <DataTable
    :value="applications"
    dataKey="applicationId"
    responsiveLayout="scroll"
    :scrollable="true"
    scrollHeight="flex"
    :paginator="true"
    :rows="50"
    :loading="loading"
    showGridlines
    class="p-datatable-sm clickable"
    v-model:filters="filter"
    :globalFilterFields="['status', 'applicationId', 'countryCode']"
  >
    <template #loading>Loading Applications</template>
    <template #header>
      <div class="fw-header">
        <div><h6 class="mt-2 ml-2"><i class="fas fa-badge-check"></i> Certifyr</h6></div>
        <div>
          <div class="w-full mt-1">
            <span class="p-input-icon-left w-full">
              <i class="fa fa-search" />
              <InputText
                placeholder="Search"
                :class="'fullWidthInput'"
                v-model="filter['global'].value"
                type="search"
              />
            </span>
          </div>
        </div>
        <div>
          <button
            class="p-button p-component p-button-icon-only p-button-rounded-light ml-2"
            type="button"
            aria-haspopup="true"
            aria-controls="overlay_menu"
          >
            <span class="fa fa-filter p-button-icon" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </template>

    <Column header="Status"></Column>
    <Column header="Submitted"></Column>
    <Column header="Ref#"></Column>
    <Column header="Document Type"></Column>
    <Column header="src"></Column>
    <Column header="Company"></Column>
    <Column header="Country"></Column>
    <Column header="Type"></Column>
    <Column header="Location"></Column>
    <Column header="Progress"></Column>
  </DataTable>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      applications: [],
      filter: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      },
      loading: false
    };
  } //data()
};
</script>

<style></style>
